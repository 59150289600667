<template>
  <div class="sidebar">
    <h2>New prospect</h2>
    <router-link :to="{ name: 'AddProspect' }"><button class="blue-button">+ NEW</button></router-link>
    <form class="search" @submit="submitSearch">
      <h3>Search</h3>
      <input type="text" v-model="website" id="website" name="website" autocomplete="off" placeholder="Website">
      <input type="text" v-model="company" id="company" name="company" autocomplete="off" placeholder="Company">
      <button id="search" class="blue-button" :disabled="sendingSearch">{{ searching }}</button>
    </form>
    <div v-if="history.length > 0" class="history">
      <h3>My history</h3>
      <ul>
        <li v-for="(h, key) in history" :key="key">
          {{ h.company == '' ? 'No company name' : h.company }} (<a :href="'/prospect/view/' + h.id">{{ addSpaces(h.websites) }}</a>)
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
    name: 'Sidebar',
    data() {
        return {
            website: '',
            company: '',
            sendingSearch: false,
            history: [],
        }
    },
    props: [],
    async created() {

      // get history
      try {
          let response = await this.$http.get('/dashboard/history');

          this.history = response.data.history;

      } catch(error) {
          this.error = error;
      }

    },
    methods: {
      async submitSearch(evt) {

          evt.preventDefault();

          this.sendingSearch = true;

          let result = null;

          if ((this.website != '') || (this.company != '')) {

            try {

                result = await this.$http.post('/dashboard/website', {
                    website: this.website,
                    company: this.company
                });

                this.sendingSearch = false;

                this.website = '';

                if (result.data.result.length == 0) {

                  return this.$router.push({
                    name: 'SearchResults',
                    params: {
                      "results": []
                    }
                  });

                } else if (result.data.result.length > 1) {

                  return this.$router.push({
                      name: 'SearchResults',
                      params: {
                        "results": result.data.result
                      }
                  });

                } else if (result.data.result[0].id != this.id) {

                  return this.$router.push({
                      name: 'ViewProspect',
                      params: {
                        "id": result.data.result[0].id
                      }
                  });
                }

            } catch(error) {
              this.sendingSearch = false;
              window.scrollTo({ top: 0, behavior: 'smooth' });
              if (error.response.data.error) {
                  this.error = error.response.data.error;
              } else if (error.response.data.errors) {
                  this.errors = error.response.data.errors;
              } else {
                this.error = error;
              }
            }

          } else {
            this.sendingSearch = false;
          }

      },
      addSpaces(websites) {
        return websites.split(',').join(', ');
      },
    },
    computed: {
        searching() {
            if (this.sending == true) {
                return "Searching...";
            } else {
                return "Search";
            }
        }
    },

}
</script>
