<template>
  <div>
    <Header />
    <main>
      <div class="wrapper">
        <div class="content">
          <h2>Log in</h2>
          <div class="new">
            <form class="newform login" @submit="processLogin">
              <input v-model="form.email" type="email" id="email" name="email" autocomplete="off" placeholder="E-mail Address" maxlength="50">
              <input v-model="form.password" type="password" id="password" name="password" autocomplete="off" placeholder="Password" maxlength="50">
              <button id="login" class="blue-button" :value="login" :disabled="sending">{{ login }}</button>
            </form>

            <div v-if="error">
              <p>{{ error }}</p>
            </div>

            <div>
              <p v-if="formErrors.emailEmpty">Error: E-mail Address is required</p>
              <p v-if="formErrors.emailInvalid">Error: Invalid E-mail Address</p>
              <p v-if="formErrors.emailTooLong">Error: E-mail Address must be shorter than 50 characters</p>
              <p v-if="formErrors.passwordEmpty">Error: Password cannot be blank</p>
              <p v-if="formErrors.passwordTooLong">Error: Password must be shorter than 50 characters</p>
            </div>

          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>

import Header from '@/components/sitewide/Header.vue';
//import HeaderAuth from '@/components/sitewide/HeaderAuth.vue';

export default {
    name: 'Login',
    components: {
          Header
    },
    data() {
        return {
            form: {
                email: '',
                password: '',
            },
            formErrors: {
                emailEmpty: false,
                emailInvalid: false,
                emailTooLong: false,
                passwordEmpty: false,
                passwordTooLong: false,
            },
            sending: false,
            error: null,
            errors: null,
            backendError: null,
            clicks: null,
            success: null,
            resetEmail: null
        }
    },
    computed: {
        login() {
            if (this.sending == true) {
                return "Logging in...";
            } else {
                return "Log In";
            }
        },
    },
    methods: {
        validateForm(form) {

            // check the entire form in one go rather than updating formErrors one by one
            const errors = {};

            if (!form.email)
              errors.emailEmpty = true;

            if (form.email.length > 50)
              errors.emailTooLong = true;

            if (form.email && !(/^\S+@\S+\.\S+$/.test(form.email)))
              errors.emailInvalid = true;

            if (!form.password)
              errors.passwordEmpty = true;

            if (form.password.length > 50)
              errors.passwordTooLong = true;

            return errors;
        },
        async processLogin(evt) {

            evt.preventDefault();

            this.formErrors = this.validateForm(this.form);

            // if formErrors is not empty
            if (Object.keys(this.formErrors).length) return;

            this.sending = true;

            try {
                const response = await this.$http.post('/authentication/login',
                    {
                        email: this.form.email,
                        password: this.form.password
                    }
                );
                this.sending = false;
                const token = response.data.result;

                await this.$store.dispatch('login', { token });

                return this.$router.push({
                        name: 'Dashboard'
                });

            } catch(error) {

                if (error.response.data.error) {
                    this.error = error.response.data.error;
                } else {
                    if (error && error.response && error.response.data && error.response.data.errors) {
                      this.errors = error.response.data.errors;
                    } else {
                      this.error = error;
                    }
                }

            } finally {
                this.sending = false;
            }
        }
    },
    watch: {
        'form.email'() {
            if (this.form.emailEmpty || this.formErrors.emailInvalid || this.formErrors.emailTooLong) {
              this.formErrors.emailEmpty = false;
              this.formErrors.emailInvalid = false;
              this.formErrors.emailTooLong = false;
            }
        },
        'form.password'() {
            if (this.formErrors.passwordEmpty || this.formErrors.passwordTooLong) {
              this.formErrors.passwordEmpty = false;
              this.formErrors.passwordTooLong = false;
            }
        },
    }
}
</script>
