<template>
    <div>
        <!-- no data -->
    </div>
</template>
<script>
export default {
  name: 'Logout',
  async created() {
      try {
          await this.$store.dispatch('logout');
          this.$router.push({ name: 'Login'});
      } catch(error) {
          console.log(error);
      }
  }
}
</script>
