<template>
  <div>
    <Header />
    <main>
      <div class="wrapper">
        <div class="content">
          <h2>My need to call</h2>
          <p class="links">Sort by: <a href="#" @click="sortBy('myNeedToContact', 'most')">most recently updated</a> &#x25cf; <a href="#" @click="sortBy('myNeedToContact', 'least')">least recently updated</a></p>
          <h2>My need to follow up</h2>
          <p class="links">Sort by: <a href="#" @click="sortBy('myNeedToFollow', 'most')">most recently updated</a> &#x25cf; <a href="#" @click="sortBy('myNeedToFollow', 'least')">least recently updated</a></p>
          <h2>My paused</h2>
          <p class="links">Sort by: <a href="#" @click="sortBy('myPaused', 'most')">most recently updated</a> &#x25cf; <a href="#" @click="sortBy('myPaused', 'least')">least recently updated</a></p>
          <h2>My don't contact</h2>
          <p class="links">Sort by: <a href="#" @click="sortBy('myDontContact', 'most')">most recently updated</a> &#x25cf; <a href="#" @click="sortBy('myDontContact', 'least')">least recently updated</a></p>
        </div>
        <Sidebar />
      </div>
    </main>
  </div>
</template>

<script>
import Header from '@/components/sitewide/Header.vue';
import Sidebar from '@/components/dashboard/Sidebar.vue';

export default {
    name: 'Dashboard',
    data() {
        return {
            error: '',
            ready: false,
            history: []
        }
    },
    components: {
        Header,
        Sidebar
    },
    methods: {
      addSpaces(websites) {
        return websites.split(',').join(', ');
      },
      async sortBy(option, sort) {

        let result = null;

        try {

            result = await this.$http.post('/dashboard/myprospects', {
                option: option,
                sort: sort
            });

            return this.$router.push({
                  name: 'SearchResults',
                  params: {
                    "results": result.data.result
                  }
            });

        } catch(error) {
          this.sendingSearch = false;
          window.scrollTo({ top: 0, behavior: 'smooth' });
          if (error.response.data.error) {
              this.error = error.response.data.error;
          } else if (error.response.data.errors) {
              this.errors = error.response.data.errors;
          } else {
            this.error = error;
          }
        }


      }
    },
    async created() {

      // get history
      try {
          let response = await this.$http.get('/dashboard/history');

          this.history = response.data.history;

          this.ready = true;

      } catch(error) {
          this.error = error;
          this.ready = true;
      }

    },
}
</script>
