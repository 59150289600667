import Axios from 'axios';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

// auto login if token is saved
const token = localStorage.getItem('token');

Vue.prototype.$http = Axios;
if (token) Vue.prototype.$http.defaults.headers.common['Authorization'] = token;

Vue.prototype.$http.defaults.baseURL = process.env.VUE_APP_BASE_URL;

new Vue({
    Axios,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
