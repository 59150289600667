const state = {
    language: localStorage.getItem('language') || 'en',
    paid: localStorage.getItem('paid') === 'true' || false
};

const mutations = {
    SET_LANGUAGE(state, language) {
        state.language = language;
    },
    SET_PAID(state, paid) {
        state.free = paid;
    }
};

const actions = {
    setLanguage({commit}, language) {
        commit('SET_LANGUAGE', language);
        localStorage.setItem('language', language);
    },
    setPaid({commit}, paid) {
        commit('SET_PAID', paid);
        localStorage.setItem('paid', paid);
    }
};

const getters = {
    getLanguage(state) {
        return state.language;
    },
    isPaid(state) {
        return state.paid;
    },
};

export default {
    state,
    mutations,
    actions,
    getters
}
