<template>
  <div>
    <Header />
    <main>
      <div class="wrapper">
        <div class="content">
          <h2>Search Results</h2>
          <ul v-if="results.length">
            <li v-for="(h, key) in results" :key="key">
              {{ h.company == '' ? 'No company name' : h.company }} (<a :href="'/prospect/view/' + h.id">{{ addSpaces(h.websites) }}</a>)
            </li>
          </ul>
          <span v-else>No results found.</span>
        </div>
        <Sidebar />
      </div>
    </main>
  </div>
</template>

<script>
import Header from '@/components/sitewide/Header.vue';
import Sidebar from '@/components/dashboard/Sidebar.vue';

export default {
    name: 'SearchResults',
    props: [ 'results'],
    data() {
        return {
            error: '',
            ready: false,
            history: []
        }
    },
    components: {
        Header,
        Sidebar
    },
    methods: {
      addSpaces(websites) {
        return websites.split(',').join(', ');
      },
    },
    async created() {

      // get history
      try {
          let response = await this.$http.get('/dashboard/history');

          this.history = response.data.history;

          this.ready = true;

      } catch(error) {
          this.error = error;
          this.ready = true;
      }

    },
}
</script>
