<template>
  <div>
    <Header />
    <main>
      <div class="wrapper">
        <div class="content">
          <h2>Add new prospect</h2>
          <div class="new">

            <div v-if="error" class="error">
              <h2>{{ error }}</h2>
            </div>

            <div v-if="formErrors.companyTooLong ||
                        formErrors.countryTooLong ||
                        formErrors.industryTooLong ||
                        formErrors.websiteEmpty ||
                        formErrors.nameTooLong ||
                        formErrors.websiteInvalid ||
                        formErrors.nameTooLong ||
                        formErrors.titleTooLong ||
                        formErrors.telephoneTooLong ||
                        formErrors.emailTooLong" class="error">
              <h2 v-if="formErrors.companyTooLong">Company name must be shorter than 50 characters</h2>
              <h2 v-if="formErrors.countryTooLong">Country name must be shorter than 50 characters</h2>
              <h2 v-if="formErrors.industryTooLong">Industry must be shorter than 50 characters</h2>
              <h2 v-if="formErrors.websiteEmpty">Website is required</h2>
              <h2 v-if="formErrors.nameTooLong">Website must be shorter than 50 characters</h2>
              <h2 v-if="formErrors.websiteInvalid">Website is invalid (Use the format <i>domain.com</i>)</h2>
              <h2 v-if="formErrors.nameTooLong">Contact's name must be shorter than 50 characters</h2>
              <h2 v-if="formErrors.titleTooLong">Title must be shorter than 50 characters</h2>
              <h2 v-if="formErrors.telephoneTooLong">Telephone must be shorter than 50 characters</h2>
              <h2 v-if="formErrors.emailTooLong">E-mail address must be shorter than 50 characters</h2>
            </div>

            <form class="newform" @submit="submitForm">
              <div class="newflex">
                <input v-model="prospect.company" maxlength="50" type="text" ref="company" id="company" name="company" autocomplete="off" placeholder="Company">
                <input v-model="prospect.country" maxlength="50" type="text" ref="country" id="country" name="country" autocomplete="off" placeholder="Country">
                <input v-model="prospect.industry" maxlength="50" type="text" id="industry" name="industry" autocomplete="off" placeholder="Industry">
              </div>
              <div id="website_container" v-for="(website, key) in prospect.websites" :key="key">
                <input v-model="website.website" maxlength="50" type="text" id="website" name="website" autocomplete="off" placeholder="Website">
              </div>
              <span class="add-another-website"><a href="#" @click="addAnotherWebsite()">+ Add another website</a></span>
              <div id="contact_container">
                <div class="contact_holder" v-for="(contact, key) in prospect.contacts" :key="key">
                  <input v-model="contact.name" maxlength="50" type="text" id="name" name="name" autocomplete="off" placeholder="Contact name">
                  <input v-model="contact.title" maxlength="50" type="text" id="title" name="title" autocomplete="off" placeholder="Job title">
                  <input v-model="contact.telephone" maxlength="50" type="text" id="telephone" name="telephone" autocomplete="off" placeholder="Telephone">
                  <input v-model="contact.email" maxlength="50" type="email" id="email" name="email" autocomplete="off" placeholder="Email">
                </div>
              </div>
              <span class="add-another-contact"><a href="#" @click="addAnotherContact()">+ Add another contact</a></span>
              <div class="status">
                <input type="text" id="status" name="status" placeholder="Status" readonly>
                <select v-model="prospect.status" maxlength="50" id="select">
                  <option value="1" selected>Need to contact</option>
                  <option value="2">Need to follow up</option>
                  <option value="3">Paused</option>
                  <option value="4">Don't contact</option>
                </select>
              </div>
              <div class="owner">
                <input type="text" id="owner" name="owner" placeholder="Owner" readonly>
                <select v-model="prospect.owner" maxlength="50" id="select">
                  <option value="1">Trey Vanes</option>
                  <option value="2">Lauren Ferreira</option>
                </select>
              </div>
              <button id="register" class="blue-button" :disabled="sending">{{ create }}</button>
            </form>

          </div>
        </div>
        <Sidebar />
      </div>
    </main>
  </div>
</template>

<script>
import Header from '@/components/sitewide/Header.vue';
import Sidebar from '@/components/dashboard/Sidebar.vue';

export default {
    name: 'AddProspect',
    data() {
        return {
            error: '',
            ready: false,
            prospect: {
              company: '',
              country: '',
              industry: '',
              websites: [
                {
                  website: '',
                }
              ],
              contacts: [
                {
                  name: '',
                  title: '',
                  telephone: '',
                  email: '',
                }
              ],
              status: 1,
              owner: this.$store.getters.getId || localStorage.getItem('id')
            },
            formErrors: {
                companyTooLong: false,
                countryTooLong: false,
                industryTooLong: false,
                websiteEmpty: false,
                websiteTooLong: false,
                websiteInvalid: false,
                nameTooLong: false,
                titleTooLong: false,
                telephoneTooLong: false,
                emailTooLong: false
            },
            sending: false,
            history: []
        }
    },
    components: {
          Header,
          Sidebar
    },
    async created() {
      // get history
      try {
          let response = await this.$http.get('/dashboard/history');

          this.history = response.data.history;

          this.ready = true;

      } catch(error) {
          this.error = error;
          this.ready = true;
      }
    },
    computed: {
        create() {
            if (this.sending == true) {
                return "Adding...";
            } else {
                return "Add";
            }
        },
    },
    methods: {
        addSpaces(websites) {
          return websites.split(',').join(', ');
        },
        addAnotherWebsite() {
          this.prospect.websites.push({
            website: ''
          })
        },
        addAnotherContact() {
          this.prospect.contacts.push({
            name: '',
            title: '',
            telephone: '',
            email: '',
          })
        },
        async addNewProspect() {

            this.sending = true;

            try {
                let result = await this.$http.post('/dashboard/prospect/create', {
                    prospect: this.prospect
                });

                return this.$router.push({
                    name: 'ViewProspect',
                    params: {
                      "id": result.data.id
                    }
                });

            } catch(error) {
              this.sending = false;
              window.scrollTo({ top: 0, behavior: 'smooth' });
              if (error.response.data.error) {
                  this.error = error.response.data.error;
              } else if (error.response.data.errors) {
                  this.errors = error.response.data.errors;
              } else {
                this.error = error;
              }
            }

        },
        async submitForm(evt) {

            evt.preventDefault();

            this.clearErrors();

            this.formErrors = this.validateForm(this.prospect);

            // if formErrors is not empty
            if (Object.keys(this.formErrors).length) {
              window.scrollTo({ top: 0, behavior: 'smooth' });
              return;
            }

            // everything ok
            this.addNewProspect();
        },
        clearErrors() {

            this.error = null;
            this.formErrors.companyTooLong = false;
            this.formErrors.countryTooLong = false;
            this.formErrors.industryTooLong = false;
            this.formErrors.websiteEmpty = false;
            this.formErrors.websiteTooLong = false;
            this.formErrors.websiteInvalid = false;
            this.formErrors.nameTooLong = false;
            this.formErrors.titleTooLong = false;
            this.formErrors.telephoneTooLong = false;
            this.formErrors.emailTooLong = false;

        },
        validateForm(prospect) {

            // check the entire form in one go rather than updating formErrors one by one
            const errors = {};

            if (prospect.company.length > 50)
              errors.companyTooLong = true;

            if (prospect.country.length > 50)
              errors.countryTooLong = true;

            if (prospect.industry.length > 50)
              errors.industryTooLong = true;

            if (prospect.websites[0].website == '')
              errors.websiteEmpty = true;

            for (let i = 0; i < prospect.websites.length; i++) {

              if (prospect.websites[i].website && prospect.websites[i].website.length > 50)
                errors.websiteTooLong = true;

              if (prospect.websites[i].website && prospect.websites[i].website != '' && !(/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](\.[a-zA-Z]{2,})+$/.test(prospect.websites[i].website)))
                errors.websiteInvalid = true;

              if (prospect.websites[i].website && prospect.websites[i].website != '' && prospect.websites[i].website.substring(0,4) == 'www.')
                errors.websiteInvalid = true;

            }

            for (let i = 0; i < prospect.contacts.length; i++) {
              if (prospect.contacts[i].name && prospect.contacts[i].name.length > 50)
                errors.nameTooLong = true;

              if (prospect.contacts[i].title && prospect.contacts[i].title.length > 50)
                errors.titleTooLong = true;

              if (prospect.contacts[i].telephone && prospect.contacts[i].telephone.length > 50)
                errors.telephoneTooLong = true;

              if (prospect.contacts[i].email && prospect.contacts[i].email.length > 50)
                errors.emailTooLong = true;

            }

            return errors;
        },
    },
    watch: {
        'prospect.company'() {
            if (this.formErrors.companyTooLong)
              this.formErrors.companyTooLong = false;
        },
        'prospect.country'() {
            if (this.formErrors.countryTooLong)
              this.formErrors.countryTooLong = false;
        },
        'prospect.industry'() {
            if (this.formErrors.industryTooLong)
              this.formErrors.industryTooLong = false;
        },
        'prospect.websites': {
          deep: true,
          handler() {
            this.error = '';

            for (let i = 0; i < this.prospect.websites.length; i++) {
              if (this.formErrors.websiteEmpty || this.formErrors.websiteTooLong || this.formErrors.websiteInvalid) {
                this.formErrors.websiteEmpty = false;
                this.formErrors.websiteTooLong = false;
                this.formErrors.websiteInvalid = false;
              }
            }
          }
        },
        'prospect.contacts': {
          deep: true,
          handler() {
            for (let i = 0; i < this.prospect.contacts.length; i++) {
              if (this.formErrors.nameTooLong)
                this.formErrors.nameTooLong = false;

              if (this.formErrors.titleTooLong)
                this.formErrors.titleTooLong = false;

              if (this.formErrors.telephoneTooLong)
                this.formErrors.telephoneTooLong = false;

              if (this.formErrors.emailTooLong)
                this.formErrors.emailTooLong = false;
            }
          }
        },
    },
    mounted() {
        this.$refs.company.focus();
    }
}
</script>

<style>
  .error {
    border: 1px solid red;
    background-color: pink;
    padding: 20px;
  }
</style>
