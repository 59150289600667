<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
    name: 'App',
    data() {
        return {
            error: null
        }
    },
    methods: {
        isAuthenticated() {
            return this.$store.getters.isAuthenticated;
        }
    },
    created() {
        this.$store.dispatch('setLanguage', localStorage.getItem('language') || 'en');
    }
}
</script>
