<template>
  <div>
    <Header />
    <main>
      <div class="wrapper">
        <div class="content">
          <h2>Registration</h2>
          <div class="new">
            <form class="newform register" @submit="processRegistration">
              <input v-model="form.fullName" maxlength="50" type="text" id="person" name="person" autocomplete="off" placeholder="Sales Agent's Name">
              <input v-model="form.email" maxlength="50" type="email" id="email" name="email" autocomplete="off" placeholder="E-mail Address">
              <input v-model="form.password" maxlength="50" type="text" id="password" name="password" autocomplete="off" placeholder="Password">
              <button id="register" class="btn" :disabled="sending">{{ create }}</button>
            </form>

            <div v-if="error">
              <p>{{ error }}</p>
            </div>

            <div>
              <p v-if="formErrors.fullNameEmpty">Contact Person is required</p>
              <p v-if="formErrors.fullNameTooLong">Contact Person must be shorter than 50 characters</p>
              <p v-if="formErrors.fullNameInvalid">Contact Person cannot contain more than four words</p>
              <p v-if="formErrors.emailEmpty">E-mail Address is required</p>
              <p v-if="formErrors.emailInvalid">E-mail Address in invalid</p>
              <p v-if="formErrors.emailTooLong">E-mail Address must be shorter than 50 characters</p>
              <p v-if="formErrors.passwordEmpty">Password cannot be blank</p>
              <p v-if="formErrors.passwordTooLong">Password must be shorter than 50 characters</p>
            </div>

          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>

import Header from '@/components/sitewide/Header.vue';
//import HeaderAuth from '@/components/sitewide/HeaderAuth.vue';

export default {
    name: 'Registration',
    components: {
          Header
      //  HeaderAuth,
    },
    data() {
      return {
            form: {
                fullName: '',
                email: '',
                password: ''
            },
            formErrors: {
                fullNameEmpty: false,
                fullNameTooLong: false,
                fullNameInvalid: false,
                emailEmpty: false,
                emailInvalid: false,
                emailTooLong: false,
                passwordEmpty: false,
                passwordTooLong: false
            },
            sending: false,
            error: null,
            errors: null
        }
    },
    computed: {
        create() {
            if (this.sending == true) {
                return "Creating...";
            } else {
                return "Create";
            }
        },
        year() {
            return new Date().getFullYear();
        }
    },
    methods: {
        async processRegistration(evt) {

            evt.preventDefault();

            this.formErrors = this.validateForm(this.form);

            // if formErrors is not empty
            if (Object.keys(this.formErrors).length) return;

            this.sending = true;

            try {
                await this.$http.post('/authentication/register',
                    {
                        fullName: this.form.fullName,
                        email: this.form.email,
                        password: this.form.password
                    }
                );
                this.$router.push({
                    name: 'Login'
                })
            } catch(error) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                if (error.response.data.error) {
                    this.error = error.response.data.error;
                } else {
                    this.errors = error.response.data.errors;
                }
            } finally {
                this.sending = false;
            }
        },
        validateForm(form) {

            // check the entire form in one go rather than updating formErrors one by one
            const errors = {};

            if (!form.fullName)
              errors.fullNameEmpty = true;

            if (form.fullName.length > 50)
              errors.fullNameTooLong = true;

            if (form.fullName.split(" ").length-1 > 3)
              errors.fullNameInvalid = true;

            if (!form.email)
              errors.emailEmpty = true;

            if (form.email.length > 50)
              errors.emailTooLong = true;

            if (form.email && !(/^\S+@\S+\.\S+$/.test(form.email)))
              errors.emailInvalid = true;

            if (!form.password)
              errors.passwordEmpty = true;

            if (form.password.length > 50)
              errors.passwordTooLong = true;

            return errors;
        }
    },
    watch: {
        'form.fullName'() {
            if (this.formErrors.fullNameEmpty || this.formErrors.fullNameTooLong || this.formErrors.fullNameInvalid) {
              this.formErrors.fullNameEmpty = false;
              this.formErrors.fullNameTooLong = false;
              this.formErrors.fullNameInvalid = false;
            }
        },
        'form.email'() {
            if (this.formErrors.emailEmpty || this.formErrors.emailInvalid || this.formErrors.emailTooLong) {
              this.formErrors.emailEmpty = false;
              this.formErrors.emailInvalid = false;
              this.formErrors.emailTooLong = false;
            }
        },
        'form.password'() {
            if (this.formErrors.passwordEmpty || this.formErrors.passwordTooLong) {
              this.formErrors.passwordEmpty = false;
              this.formErrors.passwordTooLong = false;
            }
        },
    }
}
</script>
