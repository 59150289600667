import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';

// set up Axios, same as main.js
import Axios from 'axios';
Axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

import AddProspect from '@/views/dashboard/AddProspect.vue';
import Dashboard from '@/views/dashboard/Dashboard.vue';
import Login from '@/views/authentication/Login.vue';
import Logout from '@/views/authentication/Logout.vue';
import Registration from '@/views/authentication/Registration.vue';
import SearchResults from '@/views/dashboard/SearchResults.vue';
import ViewProspect from '@/views/dashboard/ViewProspect.vue';
import Http404 from '@/views/Http404.vue';

Vue.use(VueRouter)

// hit the backend each time
const isAuthenticated = async (to, from, next) => {
    if (store.getters.isAuthenticated) {
        try {
            await Axios.get('/authentication/validate');
            return next();
        } catch(error) {
            store.dispatch('logout');
            return next('/login');
        }
    } else {
        store.dispatch('logout');
        return next('/login');
    }

}

const redirectIfAuthenticated = (to, from, next) => {
    const token = localStorage.getItem("token");
    if (token) return next('/');
    return next();
}

const routes = [
    {
        path: '/prospect/create',
        name: 'AddProspect',
        component: AddProspect,
        beforeEnter: isAuthenticated
    },
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        beforeEnter: isAuthenticated
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        beforeEnter: redirectIfAuthenticated
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout,
        beforeEnter: isAuthenticated
    },
    {
        path: '/registration',
        name: 'Registration',
        component: Registration,
        beforeEnter: redirectIfAuthenticated
    },
    {
        path: '/results',
        name: 'SearchResults',
        component: SearchResults,
        props: true,
        beforeEnter: isAuthenticated
    },
    {
        path: '/prospect/view/:id',
        name: 'ViewProspect',
        component: ViewProspect,
        props: true,
        beforeEnter: isAuthenticated
    },
    {
        path: '*',
        name: 'Http404',
        component: Http404
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
